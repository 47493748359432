//====================================================
//  Function: Slider
//====================================================
function fn_components_slider(){
	fn_main_slider();
	fn_product_slider();
	fn_product_slider_3();
	fn_location_slider();
	fn_detail_slider()
}

function fn_location_slider(){
	if ($( '.js-slider-location' ).length) {
		var slider;
		
		slider = $( '.js-slider-location' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			autoplay: false,
			adaptiveHeight: true,
			autoplaySpeed: 3000,
			cssEase: 'linear',
			speed: 500,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-chevron-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-chevron-left"></i></span>',
			variableWidth: false,
			mobileFirst: true
		});
		
		$( '.js-location-trigger' ).on( 'click' , function () {
			var inst = $(this);
			var id = inst.data( 'id' );
			slider.slick('slickGoTo', id);
		});

		$( '.js-location-select' ).on( 'click' , function () {
			$( '.js-close-modal' ).trigger( 'click' );
			return false;
		});

		slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
			$( '.js-location-trigger.is-selected' ).removeClass( 'is-selected' );
			$( '.js-location-trigger[data-id="' + currentSlide + '"]' ).addClass( 'is-selected' );

			$( '.js-tabs-list-item.' + class_active + '' ).removeClass( class_active );
			$( '.js-tabs-content .js-tabs-content-item.' + class_active + '' ).removeClass( class_active );
		});
	}
}

function fn_main_slider(){
	if ($( '.js-slider-main' ).length) {
		var slider;
		slider = $( '.js-slider-main' ).slick({
			infinite: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: false,
			autoplaySpeed: 3000,
			cssEase: 'linear',
			speed: 500,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-chevron-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-chevron-left"></i></span>',
			variableWidth: false,
			mobileFirst: true
		});
	}
}

function fn_product_slider(){
	if ($( '.js-slider-product' ).length) {
		var slider;
		slider = $( '.js-slider-product' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 3000,
			cssEase: 'linear',
			speed: 500,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-chevron-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-chevron-left"></i></span>',
			variableWidth: false,
			mobileFirst: true,
			responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 990,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                  }
                },
                {
                    breakpoint: 200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                  }
                }
            ]
		});
	}
}

function fn_product_slider_3(){
	if ($( '.js-slider-product-3' ).length) {
		var slider;
		slider = $( '.js-slider-product-3' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 3000,
			cssEase: 'linear',
			speed: 500,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-chevron-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-chevron-left"></i></span>',
			variableWidth: false,
			mobileFirst: true,
			responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 990,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                  }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                  }
                },
                {
                    breakpoint: 200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                  }
                }
            ]
		});
	}
}


function fn_detail_slider(){
	if ($( '.js-slider-detail-main' ).length) {
		$( '.js-slider-detail-main' ).slick({
			infinite: true,
			dots: false,
			slidesToShow:1,
			slidesToScroll: 1,
			autoplay: false,
			arrows: false,
			autoplaySpeed: 2000,
			cssEase: 'linear',
			speed: 500,
			fade: true,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-chevron-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-chevron-left"></i></span>',
			variableWidth: false,
			mobileFirst: true,
			asNavFor:  '.js-slider-detail-thumbnail' 
		});
	}

	if ($( '.js-slider-detail-thumbnail' ).length) {
		$( '.js-slider-detail-thumbnail' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 4,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 2000,
			cssEase: 'linear',
			speed: 500,
			asNavFor: '.js-slider-detail-main' ,
			focusOnSelect: true,
			variableWidth: false,
			nextArrow: '<span class="slick-arrows slick-arrows-side slick-next"><i class="icon icon-chevron-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-arrows-side slick-prev"><i class="icon icon-chevron-left"></i></span>',
			responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 990,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                  }
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                  }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                  }
                },
                {
                    breakpoint: 200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                  }
                }
            ]
		});
	}
}