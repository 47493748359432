//====================================================
//  Function: Show/hidden
//====================================================
function fn_parts_show_hidden(){
	if ($( '.js-active-class-toggle-trigger' ).length){
		$( '.js-active-class-toggle-trigger' ).bind( 'click' , function() {
			if ($( '.js-dropdown-block-normal' ).hasClass(class_active)){
				$( '.js-dropdown-block-normal' ).removeClass(class_active)
			}
			$(this).closest( '.js-active-block' ).toggleClass( class_active ).find( '.js-hidden-content' ).toggleClass( class_active );
			if ($( this ).hasClass( 'menu-action-link-search' )){
				$( '#search-input' ).focus();
			}
		});
	}

	if ($( '.js-active-class-trigger' ).length){
		$( '.js-active-class-trigger' ).bind( 'click' , function() {
			$(this).closest( '.js-active-block' ).find( '.js-hidden-content' ).addClass( class_active );
		});
	}

	if ($( '.js-remove-class-trigger' ).length){
		$( '.js-remove-class-trigger' ).bind( 'click' , function() {
			$(this).closest( '.js-remove-block' ).find( '.js-hidden-content' ).removeClass( class_active );
		});
	}

	if ($('.js-hidden-trigger-show').length){
		$(".js-hidden-trigger-show").bind('click', function() {
			var block = $(this).closest('.js-hidden-block');
			block.find('.js-hidden-content').addClass(class_active);
			block.find('.js-hidden-trigger-show').hide();
			block.find('.js-hidden-trigger-hide').show();
		});
	}

	if ($('.js-hidden-trigger-hide').length){
		$(".js-hidden-trigger-hide").bind('click', function() {
			var block = $(this).closest('.js-hidden-block');
			block.find('.js-hidden-content').removeClass(class_active);
			block.find('.js-hidden-trigger-show').show();
			block.find('.js-hidden-trigger-hide').hide();

			if ($( '.js-scroll-block' ).length){
				if ($(window).width() > 768) var height = 100
				else var height = 60

				$('html, body').stop().animate({
	                scrollTop: $(this).closest( '.js-scroll-block' ).offset().top - height
	            });
			}

		});
	}

	// if ($( '.js-hidden-trigger' ).length){
	// 	$( '.js-hidden-trigger' ).bind( 'click' , function() {
	// 		$(this).hide();
	// 		$(this).closest( '.js-hidden-block' ).find( '.js-hidden-content' ).addClass( class_active );
	// 	});
	// }
}

