//====================================================
//  Initialization Textarea resize
//====================================================
function fn_components_textareaResize(){
	if ($('textarea[data-autoresize]').length){
		$("textarea[data-autoresize]").on('click', function(){
			jQuery.each(jQuery('textarea[data-autoresize]'), function() {
			    var offset = this.offsetHeight - this.clientHeight;
			 
			    var resizeTextarea = function(el) {
			        jQuery(el).css('height', 'auto').css('height', el.scrollHeight + offset);
			    };
			    jQuery(this).on('keyup input', function() { resizeTextarea(this); }).removeAttr('data-autoresize');
			});
		});
	}
}
