//====================================================
//  Function: Modal
//====================================================
function fn_components_modal(){
	if ($( '.js-modal-trigger' ).length){
		// open modal 
		$( '.js-modal-trigger' ).on( 'click' , function(){
			var inst = $(this);
			var modalId = inst.data( 'modal' );
			if ($( '.navigation' ).hasClass( 'is-collapse' )){
				$( '.navigation' ).removeClass( 'is-collapse' );
			}

			$( 'body' ).addClass('overflow-hidden');
			$( '.modal-block.is-active' ).removeClass( class_active );
			$( '.modal-overlay' ).addClass( class_active );
			$( '.modal-block[data-modal=' + modalId + ']' ).addClass( class_active );
			
			if ($('.modal-block.is-active .js-slider').length){
				$('.modal-block.is-active .js-slider').slick('resize');
			}
			return false;
		});

		// close modal 
		$( '.js-close-modal' ).on( 'click' , function(){
			$( 'body' ).removeClass('overflow-hidden');
			$( '.modal-overlay' ).removeClass( class_active );
			$( '.modal-block.is-active' ).removeClass( class_active );
			return false;
		});

		// close modal keypress key escape
		$(document).keydown(function(event) { 
			if (event.keyCode == 27 && $( '.modal-block' ).hasClass( 'is-active' )) { 
				$( '.js-close-modal' ).trigger( 'click' );
			}
		});

		// // close modal on click outside from modal box
		// $(document).on('click', function(event) {
		// 	if (!$(event.target).closest( '.modal-body' ).length) {
		// 		$( '.js-close-modal' ).trigger( 'click' );
		// 	}
		// });

	}
}