/* ==========================================================================
 *  Function: Add navingation scroll
 * ======================================================================= */
function fn_parts_navigation_scroll_resize(){
	if ($( '.navigation' ).length){
		if ($(window).scrollTop() > 80 && $(window).width() > bp_medium) {
			$( '.navigation' ).addClass( 'navigation-scroll' );
		} else {
			$( '.navigation' ).removeClass( 'navigation-scroll' );
		}
	}
}

function fn_parts_basket_scroll(){
	if ($( '.js-dropdown-block.js-dropdown-block-normal' ).length && $(window).width() < bp_small){
		$( '.js-dropdown-block.js-dropdown-block-normal' ).removeClass( class_active );
	}
}
