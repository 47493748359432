//====================================================
//  Function: Product Hover
//====================================================
function fn_components_product_hover(){
	if ($( '.js-product-hover' ).length){
		var productHoverItem = $('.js-product-hover'),
			productElement,
			productHoverItemImg,
			productHoverItemText,
			productHoverItemLink,
			productHoverResult = $('.js-product-hover-result');
		
		productHoverItem.hover(
			function() {
				productElement = $( this );
				productHoverItemImg = productElement.find('.js-product-hover-img').attr('src');
				productHoverItemText = productElement.find('.js-product-hover-text').text();
				productHoverItemLink = productElement.find('.js-product-hover-link').attr('href');
				productHoverResult.find('.js-product-hover-text').text(productHoverItemText);
				productHoverResult.find('.js-product-hover-img').attr('src', productHoverItemImg);
				productHoverResult.find('.js-product-hover-link').attr('href', productHoverItemLink);
			}
		);
	}
}

