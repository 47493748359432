// --- GLOBAL VARIABLES --- //
var $window = $(window);
var $document = $(document);
var $header = $( '.header' );
var bp_small = 768;
var bp_medium = 992;
var class_active = 'is-active';
var class_last = 'is-last';
var class_disabled = 'is-disabled';
var $this = null;
var $containers = $( 'html,body' );


// --- COMPONENTS, PARTS --- //
$( document ).ready( function(){
	// ---  COMPONENTS --- //
	fn_components_accordion();
	fn_components_quantity_input();
	fn_components_select();
	fn_components_fullscreen_navigation();
	fn_components_modal();
	fn_components_gallery();
	fn_components_zoom();
	fn_components_slider();
	fn_components_tabs();
	fn_components_product_hover();

	fn_components_textareaResize();


	// ---  PARTS --- //
	fn_parts_remove_product();
	fn_parts_show_hidden();
	fn_parts_placeholder();
	fn_parts_dropdown();
	fn_parts_navigation_scroll_resize();


});


// --- SCROLL EVENT --- //
$( document ).scroll( function () {
	fn_components_nav_scroll();
	fn_parts_navigation_scroll_resize();
	fn_parts_basket_scroll();
});

// --- RESIZE EVENT --- //
$( window ).resize( function(){
});

// --- LOAD EVENT --- //
$( window ).bind( 'load' , function () {
	fn_parts_dots();
});