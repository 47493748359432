//====================================================
//  Initialization scroll
//====================================================

function fn_components_nav_scroll(){
	if ($('.navigation').length){
	    var scroll = $(window).scrollTop();
	    
        if (scroll > 80 && scroll < 150){
	        $('header').addClass('header-scrolling');
	    } else {
	        $('header').removeClass('header-scrolling');
	    }

        if (scroll > 150){
            $('header').addClass('header-scrolling-active');
        } else {
            $('header').removeClass('header-scrolling-active');
        }
	}

    var breakpoint = $('#result-panel').data('breakpoint');

	if ($('#result-panel').length && $(window).width() > breakpoint){
    	var sectionTop = $('#basket-left-panel').innerHeight();
        var resultTop = $('#result-box').innerHeight();

        // console.log($(window).scrollTop() + resultTop);
        // console.log(sectionTop);

        if ($(window).scrollTop() > 203){

	        if ($(window).scrollTop() + resultTop - 230 > sectionTop) {
	            $('#result-panel').removeClass("active");
	            $('#result-panel').addClass("complete");
	        } else {
	            $('#result-panel').addClass("active");
	        	$('#result-panel').removeClass("complete");
	        }
        } else {
            $('#result-panel').removeClass("active");
	        $('#result-panel').removeClass("complete");
        }
    }

    // UPDATE
    if ($('#result-panel').length && $(window).width() < breakpoint){
        var sectionTop = $('#result-panel').offset().top;
        var resultTop = $('#result-panel #result-box').outerHeight();
        var buttonBarHeight = $('#button-bar').outerHeight();

        if ($(window).scrollTop() + $(window).height() > sectionTop + resultTop + buttonBarHeight) {
            $('#button-bar').removeClass("active");
        } else {
            $('#button-bar').addClass("active");
        }
    }
}