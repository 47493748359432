//====================================================
//  Initialization Fullscreeen navigation
//====================================================
function fn_components_fullscreen_navigation(){
	if ($( '.navigation' ).length && $(window).width() < bp_medium){
		$( '.navigation-trigger').on('click', function(e){
			if ($( '.js-dropdown-block-normal' ).hasClass(class_active)){
				$( '.js-dropdown-block-normal' ).removeClass(class_active)
			}
			$( 'body' ).toggleClass('overflow-hidden');
			$( '.modal-overlay-white' ).toggleClass( class_active );
			$(this).closest( '.navigation' ).toggleClass( 'is-collapse' );
			e.preventDefault();
		});
		fn_checkNavigationTrigger();

		$( '.modal-overlay-white' ).on('click', function(e){
			$( '.navigation-trigger' ).trigger( 'click' );
		});
	}
}

function fn_checkNavigationTrigger(){
	(function($){
		if($(window).width() > bp_medium && $( '.navigation' ).hasClass( 'is-collapse' )){
			$( '.navigation' ).removeClass( 'is-collapse' );
		}
	})(jQuery);
}