/* ==========================================================================
 *  Function: Dropdown
 * ======================================================================= */
function fn_parts_dropdown(){
	if ($( '.js-dropdown-trigger' ).length && $(window).width() < bp_medium){
		$( '.js-dropdown-trigger' ).on( 'click' , function () {
			if ($(this).closest( '.js-dropdown-block' ).hasClass( class_active )){
				$(this).closest( '.js-dropdown-block' ).toggleClass(class_active);
			} else {
				$( '.js-dropdown-block.' + class_active + '' ).removeClass(class_active);
				$(this).closest( '.js-dropdown-block' ).toggleClass(class_active);
				if ($( '.navigation' ).hasClass( 'is-collapse' ) && !$(this).closest( '.js-dropdown-block' ).hasClass('js-dropdown-block-header')){
					console.log("Asd");
					$( '.navigation' ).removeClass( 'is-collapse' );
					$( 'body' ).removeClass('overflow-hidden');
					$( '.modal-overlay-white' ).removeClass( class_active );
				}
			}
		});

		// // close modal on click outside from modal box
		$(document).on('click', function(event) {
			if (!$(event.target).closest( '.js-dropdown-block-normal' ).length) {
				$( '.js-dropdown-block-normal' ).removeClass(class_active);
			}
		});
	}
}